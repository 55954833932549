import { render, staticRenderFns } from "./AuthPage.html?vue&type=template&id=5461d5e0&scoped=true&"
import script from "./AuthPage.ts?vue&type=script&lang=ts&"
export * from "./AuthPage.ts?vue&type=script&lang=ts&"
import style0 from "./AuthPage.scss?vue&type=style&index=0&id=5461d5e0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5461d5e0",
  null
  
)

export default component.exports